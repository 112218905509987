import React from "react"
import { Link } from 'gatsby'
import { Layout } from '../layout'
export default function About() {
  return <Layout sidebar={false}>
    <div className="four-oh-four">
      <p>Looks like the page you are looking for couldn't be found.</p>
      <p>Check our homepage for our latest specials or our menu!</p>
      <ul>
        <li>
          <Link to='/'>home</Link>
        </li>
        <li>
          <Link to='/menu'>menu</Link>
        </li>
      </ul>
    </div>
  </Layout>
}
